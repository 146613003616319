<template>
	<article class="block ranking placeholder">
		<div class="block__content">
			<span class="block__name">
				<span class="position">
					<b-skeleton width="30" height="30"></b-skeleton>
				</span>
				<span>
					<b-skeleton width="55%" height="25"></b-skeleton>
				</span>
				</span>
				<div class="b-tooltip">
					<b-skeleton width="40" height="55px"></b-skeleton>
				</div>
		</div>
	</article>
</template>

<script>
export default {
	name: 'Placeholder'
}
</script>
